@import 'variables.less';
@import 'mixins.less';

.editor-ex-item {
  position: relative;
  padding: 1rem;
  margin-right: 1rem;
  cursor: pointer;
  transition: 0.3s;
  // width: 0;
  background-color: #fff;
  border-radius: @border-radius-base;
  border: 2px solid transparent;

  flex-basis: calc(33.33% - 1rem);
  display: flex;
  flex-direction: column;

  &.readonly {
    background-color: #efefef;
    cursor: default;
  }

  > .ant-typography {
    &:last-child {
      margin-bottom: 0;
    }
  }

  > svg {
    position: absolute;
    top: -8px;
    right: -8px;

    font-size: 16px;

    &.fa-exclamation-circle {
      color: @danger-color;
    }

    &.fa-minus-circle {
      color: @orange-color;
    }
  }

  &.scaffold {
    border: 2px dashed rgba(0, 0, 0, 0.075);

    .ant-typography {
      color: rgba(0, 0, 0, 0.5);
    }

    &:hover:not(.readonly) {
      border-color: rgba(0, 0, 0, 0.125);
    }
  }

  &:hover:not(.readonly) {
    transform: translateY(-3px) scale(1.0125, 1.0125);

    -webkit-box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.05);
    box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.05);
  }
}

.upgradeButton {
  /* Move the button to the right end */
  align-self: flex-end;
}

@hd-screen-down: ~'(max-width:1280px)';@wide-screen-down: ~'(max-width:1199px)';@desktop-down: ~'(max-width:991px)';@tablet-down: ~'(max-width:767px)';@phone-down: ~'(max-width:480px)';@small-phone-down: ~'(max-width:320px)';@primary-color: rgba(19, 167, 61, 100);@info-color: rgba(41, 74, 147, 100);@danger-color: #a71d13;@layout-body-background: #fff;@border-radius-base: 4px;@light-gray-color: #f9f9fb;@orange-color: #fbae17;@purple-color: rgba(57, 26, 131, 1);@steel-color: rgba(117, 137, 141, 1);@light-blue-color: rgba(51, 156, 215, 1);